
import { useI18n } from 'vue-i18n';
import { defineComponent, ref, Ref, computed } from 'vue';
import {
  SupplementDto,
  Supplement,
  createSupplementForm,
  isFormCreateValid,
  SupplementType,
} from '@/models/Supplement';
import { supplementsService } from '@/services/SupplementsService';
import { ServiceError } from '@/services/util/ServiceError';
import { useToast } from 'primevue/usetoast';

export default defineComponent({
  emits: ['on-create-supplement'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const toast = useToast();
    const showDialog = ref(false);
    const showValidation = ref(false);
    const supplement: Ref<Supplement> = ref({} as Supplement);
    const supplementFormDto: Ref<SupplementDto> = ref({} as SupplementDto);
    const validateForm = computed(() => createSupplementForm(supplementFormDto.value, activeDiscountCodeRef.value));
    const waitSubmit = ref(false);
    const valid = computed(() => isFormCreateValid(validateForm.value));
    const activeDiscountCodeRef: Ref<boolean> = ref(false);
    const typesOptions = computed(() => {
      return Object.keys(SupplementType).map((key) => ({
        label: t(`supplementation.type.${key}`),
        value: key,
      }));
    });

    const openDialog = (defaultSupplement: Supplement) => {
      if (!defaultSupplement) {
        return;
      }
      showValidation.value = false;
      supplement.value = defaultSupplement;
      supplementFormDto.value = new SupplementDto(defaultSupplement);
      showDialog.value = true;
    };

    const onSubmit = async () => {
      showValidation.value = true;
      if (!valid.value) {
        return;
      }
      createSupplement(supplementFormDto.value);
    };

    async function createSupplement(newSupplement: SupplementDto) {
      waitSubmit.value = true;
      const result = await supplementsService.create(newSupplement);
      waitSubmit.value = false;
      if (result instanceof ServiceError) {
        toast.add({
          severity: 'error',
          summary: `${t('messages.notifications.errorCreateSupplement')} ${t('messages.notifications.tryLater')}`,
          life: 3000,
        });
      } else {
        toast.add({
          severity: 'success',
          summary: `${t('messages.notifications.successCreateSupplement')}`,
          life: 3000,
        });
        emit('on-create-supplement');
        showDialog.value = false;
      }
    }

    return {
      showDialog,
      openDialog,
      waitSubmit,
      showValidation,
      supplementFormDto,
      validateForm,
      onSubmit,
      typesOptions,
      activeDiscountCodeRef,
    };
  },
});
