import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "l-container" }
const _hoisted_2 = {
  key: 1,
  class: "c-supplements-empty-state-container"
}

import { computed, onMounted, ref, Ref } from 'vue';
import { useRoute } from 'vue-router';
import router from '@/router';

import AppHeading from '@/components/HeadingComponent.vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
import EmptyState from '@/components/EmptyState.vue';
import SupplementsTable from '@/components/supplements/SupplementsTable.vue';
import CreateSupplementModal from '@/components/supplements/CreateSupplementModal.vue';

import { useProfileStore } from '@/store/profile.module';
import { useSupplementsStore } from '@/store/supplements.module';
import { Supplement } from '@/models/Supplement';


export default /*@__PURE__*/_defineComponent({
  __name: 'SupplementationPage',
  setup(__props) {

const supplementsStore = useSupplementsStore();
const storeProfile = useProfileStore();
const route = useRoute();
const isLoadingSupplements = ref(false);

const isAdminUser = computed(() => storeProfile.isAdmin);
const supplements: Ref<Supplement[] | undefined> = ref();
const isSupplementsListEmpty = computed(() => !supplements.value || supplements.value?.length === 0);
const supplementModalRef = ref();
onMounted(async () => {
  await loadSupplementsList();
});

const loadSupplementsList = async () => {
  isLoadingSupplements.value = true;
  await supplementsStore.fetchSupplements().then(() => {
    supplements.value = supplementsStore.supplements;
  });
  isLoadingSupplements.value = false;
};

const onSuppplementDetailClick = async (supplement: Supplement) => {
  supplementsStore.setSupplementSelected(supplement);
  await router.push(`/supplementation/${supplement.id}`);
};

const onAddSupplementDialog = () => {
  const newSupplement = {} as Supplement;
  supplementModalRef.value.openDialog(newSupplement);
};

return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(AppHeading, {
      title: _ctx.$t(_unref(route).meta.breadcrumb ? _unref(route).meta.breadcrumb[0].parent : '')
    }, _createSlots({ _: 2 }, [
      (isAdminUser.value)
        ? {
            name: "right",
            fn: _withCtx(() => [
              _createVNode(_component_Button, {
                class: "p-button p-button-icon",
                onClick: onAddSupplementDialog
              }, {
                default: _withCtx(() => [
                  _cache[0] || (_cache[0] = _createElementVNode("i", {
                    class: "icon-plus",
                    "aria-hidden": "true"
                  }, null, -1)),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('supplementation.create-supplement')), 1)
                ]),
                _: 1
              })
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["title"]),
    _createElementVNode("div", _hoisted_1, [
      (isLoadingSupplements.value)
        ? (_openBlock(), _createBlock(LoadingComponent, {
            key: 0,
            "spinner-color": "lavender-700"
          }))
        : (isSupplementsListEmpty.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(EmptyState, {
                heading: _ctx.$t('supplementation.no-data-title'),
                icon: "icon-capsules",
                text: _ctx.$t('supplementation.no-data-message'),
                textAtBottom: true
              }, null, 8, ["heading", "text"])
            ]))
          : (_openBlock(), _createBlock(SupplementsTable, {
              key: 2,
              supplements: supplements.value,
              onSupplementDetailClick: onSuppplementDetailClick
            }, null, 8, ["supplements"]))
    ]),
    _createVNode(CreateSupplementModal, {
      ref_key: "supplementModalRef",
      ref: supplementModalRef,
      onOnCreateSupplement: loadSupplementsList
    }, null, 512)
  ], 64))
}
}

})